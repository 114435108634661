import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import PublitioAPI from 'publitio_js_sdk'
import { useForm } from "react-hook-form";
import Input from "./Input";
import { BACKEND_URL } from "../constants"
import cogoToast from "cogo-toast"


const EditSlider = () => {
  const {
    register,
    getValues,
    // formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name:"",
      image: "",
      slider_id: 0
    },
  });
  const publitio = new PublitioAPI('Bd18D5TejVlG6vhm1lrG', 'ZsDXHq9Jmz9BMxW3encRZ0JD48hVPdfZ')
  const [sliders, setSliders]= useState([])
  const [sliderId, setSliderId] = useState(0)
  const [bannerId, setBannerId] = useState(0)
  const [banners, setBanners]= useState([])
  const [number,setNumber] = useState(null)
  const [loading, setLoading]= useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetch(`${BACKEND_URL}/sliders`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.statusCode == 200) {
         setSliders(response.results)
        }
      })
      .catch((error) => {
        console.error("error: ", error);
      })
      .finally(() => {
        console.log("finally")
      })
}, []);


useEffect(() => {
  fetch(`${BACKEND_URL}/banners`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.statusCode == 200) {
       setBanners(response.results)
      }
    })
    .catch((error) => {
      console.error("error: ", error);
    })
    .finally(() => {
      console.log("finally")
    })
}, []);

  return (
    <>
      <div className="login-user-container">
        <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexFlow:"column"}} className="login-user-box">
          <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexFlow:"column"}} className="login-user-placeholder">
          <div style={{display:"flex", paddingTop:"50px", gap:"10px", justifyContent:"center", alignItems:'center', flexFlow:"column"}} className="form-group">
            <select style={{width:"40%"}} className="form-control" onChange={(e) => setSliderId(e.target.value) }>
            <option value="">Wybierz tytuł banneru</option>
            {sliders.map((slider) => {
               return <option key={slider?.id} value={slider?.id} id={slider?.id}>{slider?.title}</option>
            })}
          </select>
          <select style={{width:"40%"}} className="form-control" onChange={(e) => setBannerId(e.target.value) }>
            <option value="">Wybierz grupe bannerową</option>
            {banners
            .filter((banner) => banner.slider_id == sliderId )
            .map((banner) => {
               return <option  key={banner?.id} value={banner?.id} id={banner?.id}>{banner?.name}</option>
            })}
          </select>
          {/* <label>Zmień kolejność numeracji</label>
                 <input onchange={(e) => setNumber(e.target.value)} style={{padding:"10px", width:"10%", marginBottom:"20px", textAlign:"center"}} type="number"  className="form-control"/> */}
          </div>
          {/* {number != 0 && <button className="btn btn-primary" onClick={() => navigate(`/updateSlider/${sliderId}/${bannerId}/${number}`)}  style={{marginBottom:'50px', marginTop:"10px"}}>
            Przejdz do edycji
          </button>} */}
          <button className="btn btn-primary" onClick={() => navigate(`/updateSlider/${parseInt(sliderId)}/${parseInt(bannerId)}`)}  style={{marginBottom:'50px', marginTop:"10px"}}>
            Przejdz do edycji
          </button>
        </div>
        </div>
        </div>
    </>
  );
};
export default EditSlider;
