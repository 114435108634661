import { useState } from "react";
import cogoToast from "cogo-toast";
import { Link, useNavigate } from 'react-router-dom';
import { BACKEND_URL } from "../constants"

const Login = () => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const navigate = useNavigate()

  const onChange = (e) => {
    if (e.target.name === "login") {
      setEmail(e.target.value);
    } else {
      setPass(e.target.value);
    }
  };

//   const ForgotPassword = () => {
//     fetch(
//       `${process.env.REACT_APP_API}/api/Account/ForgotPassword?Email=${email}`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     )
//       .then((res) => {
//         if (res.ok) {
//           cogoToast.success(
//             "Instukcja zmiany hasła została wysłana na podanego maila"
//           );
//           return res.json();
//         } else {
//           cogoToast.error("Podany email nie istnieje w naszej bazie danych");
//         }
//       })
//       .catch((error) => {
//         console.error("error: ", error);
//       });
//   };

  const onLogin = () => {
    if (email === "" || pass === "") {
      cogoToast.error("please fill form");
    } else {
      fetch(`${BACKEND_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: pass,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode === 200) {
            cogoToast.success("Zalogowany");
            sessionStorage.setItem("user", JSON.stringify(response));
            navigate("/");
            window.location.reload()
          } else if (response[0].statusCode === 400) {
            cogoToast.error("Nieprawidłowe hasło lub email");
          } else if (response[0].statusCode === 500) {
            cogoToast.error(
              "Nie znaleziono użytkownika, prosze spróbować jeszcze raz"
            );
          } else if (response[0].statusCode === 404) {
            cogoToast.error(
              "Nie znaleziono użytkownika, prosze spróbować jeszcze raz"
            );
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        });
    }
  };
  return (
    <>
      <div className="login-user-container">
        <div className="login-user-box">
          <div style={{width:"100%", paddingTop:"20px"}} className="login-user-header">
            <h2 style={{textAlign:"center", color:"black"}} className="login-user-header-text">Logowanie</h2>
          </div>
          <div className="login-user-placeholder">
            <div style={{display:"flex", paddingTop:"50px", justifyContent:"center", alignItems:'center', flexFlow:"column"}} class="form-group">
               <label for="exampleInputEmail1">Login</label>
               <input style={{width:'34%'}} name="login" type="email" placeholder="Podaj E-mail" onChange={onChange} className="form-control"
            />
            </div>
            <div style={{display:"flex", paddingTop:"50px", paddingBottom:'20px', justifyContent:"center", alignItems:'center', flexFlow:"column"}} class="form-group">
               <label for="exampleInputEmail1">Hasło</label>
               <input style={{width:'34%'}} name="password" type="text" placeholder="Podaj Hasło" onChange={onChange} className="form-control"
            />
             <Link to="/forgotPassword"><span>Zapomniałem hasła</span></Link>
            </div>
          </div>
          <button className="btn btn-primary" style={{marginBottom:"20px"}} onClick={onLogin}>
            Zaloguj się
          </button>
        </div>
      </div>
    </>
  );
};
export default Login;
