import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import PublitioAPI from 'publitio_js_sdk'
import { useForm } from "react-hook-form";
import Input from "./Input";
import { BACKEND_URL } from "../constants"
import cogoToast from "cogo-toast"


const UpdateSlider = () => {
  const {
    register,
    getValues,
    // formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name:"",
      title:"",
      image: "",
    },
  });
  const publitio = new PublitioAPI('Bd18D5TejVlG6vhm1lrG', 'ZsDXHq9Jmz9BMxW3encRZ0JD48hVPdfZ')
  const [banner, setBanner]= useState()
  // const initialized = useRef(false);
  const params = useParams()
  const [loading, setLoading]= useState(false)
  const navigate = useNavigate()

  useEffect(() => {
      setLoading(true);
      setTimeout(() => {
        fetch(`${BACKEND_URL}/banner/${params.banner_id}/${params.slider_id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": true,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.statusCode == 200) {
              setBanner(response.results[0]);
              console.log(response, "resp")
            }
          })
          .catch((error) => {
            console.error("error: ", error);
          })
          .finally(() => setLoading(false));
      }, [1000]);
  }, []);

  const updateSliders = async (e) => {
    setLoading(true)
    const data = getValues();
    e.preventDefault();
    try{
    let image;
    if(data.image[0] != undefined){
      image = await publitio.uploadFile(data.image[0], 'file')
    }
    const response = await fetch(`${BACKEND_URL}/updateSliders`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: data.name != "" ? data.name: banner?.name,
        title: data.title != "" ? data.title: banner?.title,
        image: image != undefined ? image.url_preview: banner?.photo,
        slider_id:params.slider_id,
        banner_id: params.banner_id
      }),
    });
    const result = await response.json();

    if (result.statusCode === 200) {
      setLoading(false)
      cogoToast.success(
        "Slider został zaaktualizowany"
      );
      navigate("/gallery")
    } else {
      cogoToast.error("Nieprawidłowe dane");
    } 
  }
  catch (error) {
    console.error("error: ", error);
  }
}

console.log(banner, "banner")

  return (
    <>
      <div className="login-user-container">
        <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexFlow:"column"}} className="login-user-box">
          <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexFlow:"column"}} className="login-user-placeholder">
          <div style={{display:"flex", paddingTop:"50px", justifyContent:"center", alignItems:'center', flexFlow:"column"}} className="form-group">
             <label>Nazwa slidera: {params.slider_id.length ? banner?.title: ""}</label>
               <input  {...register("title", {
            })} style={{padding:"10px", width:"90%", marginBottom:"20px", textAlign:"center"}} type="text" placeholder="Dodaj nazwe slidera" className="form-control"/>
               
               <label>Nazwa Bannera: {params.slider_id.length ? banner?.name: ""}</label>
               <input  {...register("name", {
            })} style={{padding:"10px", width:"90%", marginBottom:"20px", textAlign:"center"}} type="text" placeholder="Dodaj nazwe bannera" className="form-control"/>
               <label>Edytuj zdjęcie do slidera</label>
            <Input {...register("image", {
            })} type="file" max-size="200000" />
          </div>
          <button className="btn btn-primary" onClick={updateSliders} style={{marginBottom:'50px'}}>
            Zaaktualizuj banner
          </button>
        </div>
        </div>
        </div>
    </>
  );
};
export default UpdateSlider;
