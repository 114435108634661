import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";

const Header = ({user}) => {
  const [menu, setMenu] = useState(false);
  const onMenu = () => {
    if (menu === false) {
      setMenu(true);
    } else {
      setMenu(false);
    }
  };

  const logOut = () => {
    sessionStorage.clear()
    window.location.href = "/"
  }

  return (
    <header className="header">
      <div className="container header-content">
        <div className="logo">
          <Link to="/">
            <img
              width={100}
              className="logo-header"
              src={logo}
              alt="logo"
            ></img>
          </Link>
        </div>
        <i onClick={onMenu} className="fa fa-bars"></i>
        <ul className={menu === true ? "header-nav-visible" : "header-nav"}>
          {!user && <li>
            <Link className="header-link" to="/about">
              O NAS
            </Link>
          </li>}
          {!user && <li>
            <Link className="header-link" to="/contact">
              KONTAKT
            </Link>
          </li>}
          {!user && <li>
            <Link className="header-link" to="/code">
              KODEKS
            </Link>
          </li>}
          {user?.user.type === "admin" &&  <li>
            <Link className="header-link" to="/addSliders">
              DODAJ BANNER
            </Link>
          </li>}
          {user?.user.type === "admin" &&  <li>
            <Link className="header-link" to="/fillSliders">
              WYPEŁNIJ BANNER
            </Link>
          </li>}
          {user?.user.type === "admin" &&  <li>
            <Link className="header-link" to="/editSlider">
              EDYTUJ BANNER
            </Link>
          </li>}
          {user?.user.type === "admin" &&  <li>
            <Link className="header-link" to="/allSliders">
              WSZYSTKIE BANNERY
            </Link>
          </li>}
          {user?.user.type === "admin" &&  <li>
            <Link className="header-link" to="/changePassword">
              ZMIEŃ HASŁO
            </Link>
          </li>}
          {/* {user?.user.type === "admin" &&  <li>
            <Link className="header-link" to="/admin">
              EDYTUJ BANNER
            </Link>
          </li>} */}
          {!user && <li>
            <Link className="header-link" to="/private">
              POLITYKA PRYWATNOŚCI
            </Link>
          </li>}
          <li>
            <Link className="header-link" to="/gallery">
              ZREALIZOWANE PROJEKTY
            </Link>
          </li>
          {!user &&<li>
            <Link className="header-link" to="/realization">
              REALIZACJE
            </Link>
          </li>}
          {user &&
          // <li>
          //   <Link className="header-link" to="/login">
          //     LOGOWANIE
          //   </Link>
          // </li>
          <button onClick={logOut}>Wyloguj</button>}
        </ul>
      </div>
    </header>
  );
};
export default Header;
