import cogoToast from "cogo-toast"
import React, { useEffect, useRef, useState } from "react";
// import { DEMO_POSTS_NEWS } from "data/posts";
import { BACKEND_URL } from "../constants"
import AddSliders from "./AddSliders"
//

const AllBanners = () => {
  const [data,setData] = useState([])
  const [number, setNumber]= useState(0)
  const [sliders,setSliders] = useState([])
  const [loading, setLoading]= useState(false)
  const [sliderId, setSliderId] = useState(1)
  let user = sessionStorage.getItem("user")
  const initialized = useRef(false);

  const onChange = (e) => {
    setNumber(e.target.value);
};

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/allBanners`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setData(response.results)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, [JSON.parse(user)?.user.id, user]);


  useEffect(() => {
      fetch(`${BACKEND_URL}/sliders`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode == 200) {
           setSliders(response.results)
           console.log(response, "resp")
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
  }, [JSON.parse(user)?.user.id, user]);

  const changeOrderNumber = async (id) => {
    try{
      const response = await fetch(`${BACKEND_URL}/changeNumber`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        number: number,
      }),
    });
    const result = await response.json();

    if (result.statusCode === 200) {
      setLoading(false)
      cogoToast.success(
        "Kolejność występowania sliderów została zaauktualizowana"
      );
     window.location.reload()
    } else {
      cogoToast.error("Nieprawidłowe dane");
    } 
  }
  catch (error) {
    console.error("erorr: ", error);
  }}

  const onDelete = (id) => {
    setTimeout(() => {
      fetch(`${BACKEND_URL}/delete-banner`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: id
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode === 200) {
            cogoToast.success("Banner został usunięty");
            window.location.reload()
          } 
        })
        .catch((error) => console.error("error: ", error));
    }, [2000]);
  }


  const deleteSlider = (id) => {
    setTimeout(() => {
      fetch(`${BACKEND_URL}/delete-slider`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: id
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode === 200) {
            cogoToast.success("Grupa Bannerowa została usunięta");
            window.location.reload()
          } 
        })
        .catch((error) => console.error("error: ", error));
    }, [2000]);
  }


  return (
    <div className="nc-PageHomeDemo6 relative [ nc-section-rounded-md ]">
      <div className="relative overflow-hidden">
        <div className="container relative">
        <h1 style={{marginTop:"50px",marginBottom:"50px"}} className="text-2xl md:text-3xl lg:text-4xl font-semibold">Wszystkie Projekty</h1>
       <div className="option-box">
        {sliders
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((slider) => {
          return(
            <div>
            <button style={{padding:"20px", fontSize:"16px", backgroundColor:'transparent', color:slider.id == sliderId ? "red": "black",  border:"none"}} onClick={(e) => setSliderId(e.target.value)} key={slider.id} value={slider.id}>{slider.title}</button>
            <div style={{marginBottom:'10px', display:"flex", flexFlow:"column"}}>
              <span style={{fontSize:"20px"}}>Kolejnosć numeracji: {slider.orderNumber}</span>
              <label>Zmień kolejność</label>
              <div style={{display:'flex', flexFlow:"column", justifyContent:"center", alignItems:'center', gap:"10px"}}>
              <input type="number" style={{width:'27%', margin:"0 auto"}} onChange={onChange} name="number" className="form-control"/>
              <button className="btn btn-primary" onClick={() => changeOrderNumber(slider.id)}>Zauktualizuj kolejność</button>
              </div>
            </div>
            <button onClick={() => deleteSlider(slider?.id)} style={{backgroundColor:'red', width:"88%", padding:"8px", fontSize:'13px', border:"none", color:"white"}}>Usuń grupe bannerową</button>
            </div>
          )
        })}
       </div>
       <div className="project-box">
       {data
       .filter((banner) => banner.slider_id == sliderId )
       .map((banner) => {
          return(
            <div style={{display:'flex', alignItems:"center", flexFlow:'column', gap:"20px"}}>
              <h3 style={{fontSize:"20px"}}>{banner?.name}</h3>
              <img width={300} height={168} src={banner?.photo}></img>
              <button onClick={() => onDelete(banner?.id)} style={{backgroundColor:'red', width:"80%", border:"none", color:"white"}}>Usuń</button>
            </div>
          )
        })}
       </div>
        </div> 
      </div>
    </div>
  );
};

export default AllBanners;
