import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import PublitioAPI from 'publitio_js_sdk'
import { useForm } from "react-hook-form";
import Input from "./Input";
import { BACKEND_URL } from "../constants"
import cogoToast from "cogo-toast"


const InsideSlider = () => {
  const {
    register,
    getValues,
    // formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name:"",
      image: "",
      slider_id: 0
    },
  });
  const publitio = new PublitioAPI('Bd18D5TejVlG6vhm1lrG', 'ZsDXHq9Jmz9BMxW3encRZ0JD48hVPdfZ')
  const [sliders, setSliders]= useState([])
  const [loading, setLoading]= useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetch(`${BACKEND_URL}/sliders`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.statusCode == 200) {
         setSliders(response.results)
        }
      })
      .catch((error) => {
        console.error("error: ", error);
      })
      .finally(() => {
        console.log("finally")
      })
}, []);

  const fillSliders = async (e) => {
    setLoading(true)
    const data = getValues();
    e.preventDefault();
    try{
    let image;
    if(data.slider_id != 0){
      image = await publitio.uploadFile(data.image[0], 'file')
     const response = await fetch(`${BACKEND_URL}/fillSliders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: data.name,
        image: image.url_preview,
        slider_id:data.slider_id
      }),
    });
    const result = await response.json();

    if (result.statusCode === 200) {
      setLoading(false)
      cogoToast.success(
        "Slider został dodany pomyślnie"
      );
      navigate("/gallery")
    } else {
      cogoToast.error("Nieprawidłowe dane");
      } 
    }
    else{
      cogoToast.info('Prosze wypełnić wszystkie pola')
    }
  }
  catch (error) {
    console.error("erorr: ", error);
  }
}

  return (
    <>
      <div className="login-user-container">
        <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexFlow:"column"}} className="login-user-box">
          {/* <div className="login-user-header">
            <p className="login-user-header-text">Dodaj nowy banner</p>
          </div> */}
          <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexFlow:"column"}} className="login-user-placeholder">
          <div style={{display:"flex", paddingTop:"50px", justifyContent:"center", alignItems:'center', flexFlow:"column"}} class="form-group">
              <label>Nazwa slidera</label>
               <input {...register("name", {
            })} style={{padding:"10px", width:"100%", marginBottom:"20px", textAlign:"center"}} name="name" type="text" placeholder="Dodaj nazwe slidera" className="form-control"/>
            </div>
            <label>Dodaj zdjęcie do slidera</label>
            <Input {...register("image", {
            })} type="file" max-size="200000" />
          <select style={{marginBottom:'20px', width:"40%"}} className="form-control" {...register("slider_id", {
            })}>
            <option value="">Wybierz grupe bannerową</option>
            {sliders.map((slider) => {
               return <option value={slider?.id} id={slider?.id}>{slider?.title}</option>
            })}
          </select>
          </div>
          <button className="btn btn-primary" onClick={fillSliders} style={{marginBottom:'50px'}}>
            Wypełnij banner
          </button>
        </div>
      </div>
    </>
  );
};
export default InsideSlider;
