import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import cogoToast from "cogo-toast";
import { BACKEND_URL } from "../constants"


const ForgotPasword = () => {
  const [code, setCode] = useState("")
  const [email, setEmail] = useState("");
  const [loading, setLoading]= useState(false)
  const navigate = useNavigate()

  const onChange = (e) => {
      setEmail(e.target.value);
  };

  const resetPassword = async () => {
    try{
      const response = await fetch(`${BACKEND_URL}/forgotPassword`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });
    const result = await response.json();

    if (result.statusCode === 200) {
      setCode(result.code)
      setLoading(false)
      cogoToast.success(
        `Reset hasła wykonany. Twoje nowe hasło to ${result.code}`
      );
      // navigate("/");
    } else {
      cogoToast.error("Nie ma takiego emaila w bazie danych");
    } 
  }
  catch (error) {
    console.error("erorr: ", error);
  }}
  return (
    <>
      <div className="login-user-container">
        <div className="login-user-box">
          <div className="login-user-placeholder">
            <div style={{display:"flex", paddingTop:"50px", marginBottom:"20px", justifyContent:"center", alignItems:'center', flexFlow:"column"}} class="form-group">
              <label for="exampleInputEmail1">Wpisz E-mail</label>
               <input style={{padding:"10px", width:"20%", textAlign:"center"}} name="email" type="email" placeholder="Dodaj E-mail" onChange={onChange} className="form-control"/>
            </div>
          </div>
          <button className="btn btn-primary"  style={{marginBottom:"50px"}} onClick={resetPassword}>
              Wyślij prośbe o zmiane hasła
          </button>
          {code != "" && <h3>Twoje nowe hasło to {code}</h3>}
          <div>
          {code != "" && <Link to="/secretAdminlogin0123"><span style={{fontSize:"16px"}}>Przejdz do logowania</span></Link>}
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgotPasword;
