import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useEffect, useState } from "react"
import { BACKEND_URL } from "../constants"

const Gallery = () => {
  const [sliders, setSliders]= useState([])
  useEffect(() => {
    fetch(`${BACKEND_URL}/allSliders`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.statusCode == 200) {
         setSliders(response.results)
        }
      })
      .catch((error) => {
        console.error("error: ", error);
      })
      .finally(() => {
        console.log("finally")
      })
}, []);
  return (
    <div>
      <div className="slide-container">
        {sliders
        // .reverse()
        .map((slider) => {
          return(
            <div style={{paddingTop:'20px'}} key={slider.id}>
            <h3 style={{fontSize:'1.17em', marginBottom:'20px', fontWeight:"bold"}}>
            {slider.titles}
              </h3>
               <Fade>
               {slider.ids.split(",").map((element, index) =>  {
                return (
                  <div key={element.id} className="each-fade">
                 <div className="each-fade-title">{slider.names.split(",")[index]}</div>
                   <img className="image_slide" alt="przychodnia_22" src={slider.photos.split(",")[index]} />
                 </div>
                )})}
               </Fade>
               </div>
          )
        })}
      </div>
    </div>
  );
};
export default Gallery;
