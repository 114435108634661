import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import { Routes, Route } from "react-router-dom";
import About from "./components/About";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import Realization from "./components/Realization";
import Code from "./components/Code";
import Private from "./components/Private";
import Register from "./components/Register"
import Login from "./components/Login"
import AddSliders from "./components/AddSliders"
import InsideSlider from "./components/InsideSlider"
import EditSlider from "./components/EditSlider"
import UpdateSlider from "./components/UpdateSlider"
import AllBanners from "./components/AllBanners"
import ChangePasword from "./components/ChangePassword"
import ForgotPassword from "./components/ForgotPassword"

function App() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  return (
    <>
      <Header user={user}></Header>
      <div className="App">
        <div className="main">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/addSliders" element={user?.user.type === "admin" ? <AddSliders />: <Home/>} />
            <Route exact path="/changePassword" element={user?.user.type === "admin" ? <ChangePasword />: <Home/>} />
            <Route exact path="/allSliders" element={user?.user.type === "admin" ? <AllBanners />: <Home/>} />
            <Route exact path="/fillSliders" element={user?.user.type === "admin" ? <InsideSlider />: <Home/>} />
            <Route exact path="/editSlider" element={user?.user.type === "admin" ? <EditSlider />: <Home/>} />
            <Route exact path="/updateSlider/:slider_id/:banner_id" element={user?.user.type === "admin" ? <UpdateSlider />: <Home/>} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/secretAdminlogin0123" element={<Login />} />
            <Route exact path="/forgotPassword" element={<ForgotPassword/>} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/gallery" element={<Gallery />} />
            <Route exact path="/realization" element={<Realization />} />
            <Route exact path="/code" element={<Code />} />
            <Route exact path="/private" element={<Private />} />
          </Routes>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default App;
