import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import cogoToast from "cogo-toast";
import { BACKEND_URL } from "../constants"


const AddSliders = () => {
  const [title, setTitle] = useState("");
  // const [number, setNumber] = useState("");
  const [loading, setLoading]= useState(false)
  const navigate = useNavigate()

  const onChange = (e) => {
      setTitle(e.target.value);
  };

  const addSite = async () => {
    try{
      const response = await fetch(`${BACKEND_URL}/addSlider`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: title,
      }),
    });
    const result = await response.json();

    if (result.statusCode === 200) {
      setLoading(false)
      cogoToast.success(
        "Banner został dodany pomyślnie"
      );
      navigate("/fillSliders");
    } else {
      cogoToast.error("Nieprawidłowe dane");
    } 
  }
  catch (error) {
    console.error("erorr: ", error);
  }}
  return (
    <>
      <div className="login-user-container">
        <div className="login-user-box">
          {/* <div className="login-user-header">
            <p className="login-user-header-text">Dodaj nowy banner</p>
          </div> */}
          <div className="login-user-placeholder">
            <div style={{display:"flex", paddingTop:"50px", marginBottom:"20px", justifyContent:"center", alignItems:'center', flexFlow:"column"}} class="form-group">
              <label for="exampleInputEmail1">Tytuł bannera</label>
               <input style={{padding:"10px", width:"20%", textAlign:"center"}} name="title" type="text" placeholder="Dodaj tytuł Bannera" onChange={onChange} className="form-control"/>
            </div>
            {/* <div style={{display:"flex", paddingTop:"50px", marginBottom:"20px", justifyContent:"center", alignItems:'center', flexFlow:"column"}} class="form-group">
              <label for="exampleInputEmail1">Numeracja</label>
               <input style={{padding:"10px", width:"20%", textAlign:"center"}} name="number" type="number" placeholder="Dodaj numeracje Bannera" onChange={onChange} className="form-control"/>
            </div> */}
            
          </div>
          <button className="btn btn-primary"  style={{marginBottom:"50px"}} onClick={addSite}>
            Stwórz nowy banner
          </button>
        </div>
      </div>
    </>
  );
};
export default AddSliders;
